<template>
  <b-card
    class="product-edit-wrapper"
    :title="`Region: ${productEdit.region_id ? productEdit.region_id.toUpperCase() : ''}`"
  >
    <validation-observer ref="editProductValidation">
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <!-- {{ productOnEdit }} -->
          <!-- Title Previous -->
          <b-col md="3">
            <b-form-group
              label="Title"
              label-for="product-edit-title"
              class="mb-2"
            >
              <b-form-input
                id="product-edit-title"
                v-model="productEdit.title"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Title Edit -->
          <b-col md="3">
            <b-form-group
              label-for="product-edit-title"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="product-edit-title"
                  v-model="productOnEdit.title"
                  @input.native="onChangeTitle"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Category Previous -->
          <b-col md="3">
            <b-form-group
              label="Category"
              label-for="product-edit-category"
              class="mb-2"
            >
              <v-select
                id="product-edit-category"
                v-model="productEdit.category_ids"
                disabled
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productCategory"
                @input="(val) => updateProductCategory(val)"
              />
            </b-form-group>
          </b-col>
          <!-- Category edit-->
          <b-col md="3">
            <b-form-group
              label-for="product-edit-category"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  id="product-edit-category"
                  v-model="productOnEdit.category_ids"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productCategory"
                  @input="(val) => updateProductCategory(val)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Slug Previous -->
          <b-col md="3">
            <b-form-group
              label="Slug"
              label-for="product-edit-slug"
              class="mb-2"
            >
              <b-form-input
                id="product-edit-slug"
                v-model="productEdit.slug"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Slug Edit-->
          <b-col md="3">
            <b-form-group
              label-for="product-edit-slug"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Slug"
                rules="required"
              >
                <b-form-input
                  id="product-edit-slug"
                  v-model="productOnEdit.slug"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Tags Previous-->
          <b-col md="3">
            <b-form-group
              v-if="productTags.length > 0"
              label="Tags"
              label-for="tags-edit-vendor"
              class="mb-2"
            >
              <v-select
                id="region-edit-vendor"
                v-model="productEdit.tag_ids"
                disabled
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productTags"
              />
            </b-form-group>
          </b-col>
          <!-- Tags Edit-->
          <b-col md="3">
            <b-form-group
              v-if="productTags.length > 0"
              label-for="tags-edit-vendor"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Tags"
                rules="required"
              >
                <v-select
                  id="region-edit-vendor"
                  v-model="productOnEdit.tag_ids"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productTags"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Date time previous  -->
          <b-col md="3">
            <b-form-group
              label="Date time"
              label-for="product-edit-price"
              class="mb-2"
            >
              <flat-pickr
                v-model="productEdit.startDateTime"
                disabled
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
          <!-- Date time previous -->
          <b-col md="3">
            <b-form-group
              label-for="product-edit-price"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Date time"
                rules="required"
              >
                <flat-pickr
                  v-model="productOnEdit.startDateTime"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Duration previous -->
          <b-col md="3">
            <b-form-group
              label="Duration (minutes)"
              label-for="product-add-date-time"
              class="mb-2"
            >
              <b-form-input
                id="product-add-slug"
                v-model="productEdit.duration"
                type="number"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Duration -->
          <b-col md="3">
            <b-form-group
              label-for="product-add-date-time"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Duration"
                rules="required"
              >
                <b-form-input
                  id="product-add-slug"
                  v-model="productOnEdit.duration"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Roles previous -->
          <b-col md="3">
            <b-form-group
              label="Role"
              label-for="product-add-role"
              class="mb-2"
            >
              <v-select
                id="product-add-role"
                v-model="productEdit.product_roles"
                disabled
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
              />
            </b-form-group>
          </b-col>
          <!-- Roles edit -->
          <b-col md="3">
            <b-form-group
              label-for="product-add-role"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <v-select
                  id="product-add-role"
                  v-model="productOnEdit.product_roles"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productRole"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Business Challenges previous -->
          <b-col md="3">
            <b-form-group
              label="Business Challenges"
              label-for="product-add-business"
              class="mb-2"
            >
              <v-select
                id="product-add-role"
                v-model="productEdit.product_bussiness_challenges"
                multiple
                disabled
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label-for="product-add-business"
              class="mb-2 mt-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Business Challenges"
                rules="required"
              >
                <v-select
                  id="product-add-role"
                  v-model="productOnEdit.product_bussiness_challenges"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="productBussiess"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Speakers -->
          <b-col md="6">
            <b-row>
              <b-col
                cols="12"
                style="display:flex; flex-direction: column; justify-content: center;"
              >
                <b-form-group
                  label="Speakers"
                  label-for="product-speaker"
                >
                  <swiper
                    ref="speakerRef"
                    style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem;"
                    class="swiper-multiple"
                    :options="swiperOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :style="!productEdit.speakers.length ? 'height: 16rem;' :''"
                  >
                    <template v-if="productEdit.speakers.length">
                      <swiper-slide
                        v-for="(data,indexSpeaker) in productEdit.speakers"
                        :key="indexSpeaker"
                      >
                        <b-col>
                          <b-row
                            style="border: dashed 2px;
                                 border-radius: 1rem;
                                 border-color: #DB536A;
                                 cursor: pointer;
                                 height: 10rem;
                                 justify-content: center;"
                          >
                            <b-col
                              cols="10"
                            >
                              <div class="select-image">
                                <span v-if="!data.image">Select image</span>
                                <b-img
                                  v-if="data.image"
                                  fluid
                                  :src="data.image.link"
                                  style="height: 10rem; width: 20rem"
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="mt-1">
                            <b-col md="6">
                              <b-form-group
                                label="Name"
                                label-for="product-speaker-name"
                                class="mb-2"
                              >
                                <b-form-input
                                  id="product-speaker-name"
                                  v-model="productEdit.speakers[indexSpeaker].name"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group
                                label="Role"
                                label-for="product-speaker-role"
                                class="mb-2"
                              >
                                <b-form-input
                                  id="product-speaker-role"
                                  v-model="productEdit.speakers[indexSpeaker].role"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </swiper-slide>
                    </template>
                    <template v-else>
                      <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                        No item added yet!
                      </div>
                    </template>
                    <!-- Add Arrows -->
                    <div
                      slot="button-next"
                      class="swiper-button-next"
                      @click="nextSlide('speakerRef')"
                    />
                    <div
                      slot="button-prev"
                      class="swiper-button-prev"
                      @click="previousSlide('speakerRef')"
                    />
                  </swiper>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="6"
            class="mt-2"
          >
            <b-row>
              <b-col
                cols="10"
                style="display:flex; flex-direction: column; justify-content: center;"
              >
                <b-form-group
                  label-for="product-speaker"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Speaker"
                    rules="required"
                  >
                    <swiper
                      ref="speakerRefOnedit"
                      v-model="productOnEdit.speakers"
                      style="border: solid 1px; border-color: #b8c2cc; border-radius: 1rem; padding: 1rem;"
                      class="swiper-multiple"
                      :options="swiperOptions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :style="!productOnEdit.speakers.length ? 'height: 16rem;' :''"
                    >
                      <template v-if="productOnEdit.speakers.length">
                        <swiper-slide
                          v-for="(data,indexSpeaker) in productOnEdit.speakers"
                          :key="indexSpeaker"
                        >
                          <b-col>
                            <b-row
                              style="border: dashed 2px;
                          border-radius: 1rem;
                          border-color: #DB536A;
                          cursor: pointer;
                          height: 10rem;
                          justify-content: center;"
                            >
                              <b-col
                                cols="10"
                                @click="openSetImageSpeaker(indexSpeaker)"
                              >
                                <div class="select-image">
                                  <span v-if="!data.image">Select image</span>
                                  <validation-provider
                                    #default="{ errors }"
                                    style="width: 100%"
                                    name="Image"
                                    rules="required"
                                  >
                                    <b-img
                                      v-if="data.image"
                                      v-model="productOnEdit.speakers[indexSpeaker].image"
                                      fluid
                                      :src="data.image.link"
                                      style="height: 10rem; width: 20rem"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </div>
                              </b-col>
                              <b-col
                                cols="2"
                                class="back-sub-element"
                                @click="removeElemenSpeaker(indexSpeaker)"
                              >
                                <div
                                  class="sub-element"
                                >
                                  -
                                </div>
                              </b-col>
                            </b-row>
                            <b-row class="mt-1">
                              <b-col md="6">
                                <b-form-group
                                  label="Name"
                                  label-for="product-speaker-name"
                                  class="mb-2"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Name"
                                    rules="required"
                                  >
                                    <b-form-input
                                      id="product-speaker-name"
                                      v-model="productOnEdit.speakers[indexSpeaker].name"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="6">
                                <b-form-group
                                  label="Role"
                                  label-for="product-speaker-role"
                                  class="mb-2"
                                >
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Name"
                                    rules="required"
                                  >
                                    <b-form-input
                                      id="product-speaker-role"
                                      v-model="productOnEdit.speakers[indexSpeaker].role"
                                    />

                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </swiper-slide>
                      </template>
                      <template v-else>
                        <div style="height: 100%; justify-content: center; align-items: center; width: 100%; display: flex;">
                          No item added yet!
                        </div>
                      </template>
                      <!-- Add Arrows -->
                      <div
                        slot="button-next"
                        class="swiper-button-next"
                        @click="nextSlide('speakerRefOnedit')"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        @click="previousSlide('speakerRefOnedit')"
                      />
                    </swiper>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                style="padding: 1rem;"
                @click="appendElemenSpeaker()"
              >
                <div class="add-element">
                  +
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-row>
            <!-- description form previous -->
            <b-col
              md="6"
              class="mb-2"
            >
              <transition-group
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body
                  v-for="index in count"
                  :key="index"
                >
                  <!-- repeater from -->
                  <div class="border rounded p-2">
                    <!-- Language -->
                    <b-col md="12">
                      <b-form-group
                        label="Language"
                        label-for="product-edit-region"
                        class="mb-2"
                      >
                        <v-select
                          id="region-edit-region"
                          v-model="arrayDescription[index - 1].region_id"
                          disabled
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="regionOptions"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Title -->
                    <b-col md="12">
                      <b-form-group
                        label="Title"
                        label-for="product-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="product-edit-title"
                          v-model="arrayDescription[index - 1].title"
                          disabled
                        />

                      </b-form-group>
                    </b-col>
                    <!-- Summary -->
                    <b-col md="12">
                      <b-form-group
                        label="Summary"
                        label-for="product-add-Summary"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Summary"
                          rules="required"
                        >
                          <b-form-input
                            id="product-add-Summary"
                            v-model="arrayDescription[index - 1].summary"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Event Overview -->
                    <b-col cols="12">
                      <b-form-group
                        label="Event Overview"
                        label-for="product-orverview"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Event Overview"
                          rules="required"
                        >
                          <quill-editor
                            id="product-overview"
                            v-model="arrayDescription[index - 1].description"
                            disabled
                            class="editor-form"
                            :options="snowOption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </div>
                </b-card-body>
              </transition-group>
            </b-col>
            <!-- description form Edit -->
            <b-col
              md="6"
              class="mb-2"
            >
              <transition-group
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body
                  v-for="index in countOnEdit"
                  :key="index"
                >
                  <!-- repeater from -->
                  <div class="border rounded p-2">
                    <div
                      v-if="arrayDescriptionOnEdit.length > 1 && index !== 1"
                      class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                    >
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(index - 1)"
                      />
                    </div>
                    <!-- Language -->
                    <b-col md="12">
                      <b-form-group
                        label="Language"
                        label-for="product-edit-region"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Language"
                          rules="required"
                        >
                          <v-select
                            id="region-edit-region"
                            v-model="arrayDescriptionOnEdit[index - 1].region_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="languageOptions"
                            :clearable="false"
                            :disabled="index === 1"
                            @input="(val) => updateItemForm(index - 1, val)"
                            :selectable="(option) => {
                                  return !arrayDescriptionOnEdit.find(x => x.region_id.value === option.value)
                                }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Title -->
                    <b-col md="12">
                      <b-form-group
                        label="Title"
                        label-for="product-edit-title"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Title"
                          rules="required"
                        >
                          <b-form-input
                            id="product-edit-title"
                            v-model="arrayDescriptionOnEdit[index - 1].title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Summary -->
                    <b-col md="12">
                      <b-form-group
                        label="Summary"
                        label-for="product-add-Summary"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Summary"
                          rules="required"
                        >
                          <b-form-input
                            id="product-add-Summary"
                            v-model="arrayDescriptionOnEdit[index - 1].summary"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Event Overview -->
                    <b-col cols="12">
                      <b-form-group
                        label="Event Overview"
                        label-for="product-orverview"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Event overview"
                          rules="required"
                        >
                          <quill-editor
                            id="product-overview"
                            v-model="arrayDescriptionOnEdit[index - 1].description"
                            class="editor-form"
                            :options="snowOption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </div>
                </b-card-body>
              </transition-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormDescription"
              >
                Add description form
              </b-button>
            </b-col>
          </b-row>
          <b-col
            cols="12"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Featured Image
              </h4>
              <b-col>
                <b-row>
                  <!-- Asset overview -->
                  <b-col md="6">
                    <b-form-group
                      label="Event overview previous"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <div class="border rounded p-2 pb-0 mt-2">
                        <h6 v-if="imageOverview.length === 0">
                          No photos added yet
                        </h6>
                        <b-img
                          v-else
                          v-model="imageOverview"
                          center
                          fluid
                          :src="imageOverview[0].link"
                          height="100"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Header images -->
                  <b-col md="6">
                    <b-form-group
                      label="Header images previous"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <div class="border rounded p-2 pb-0 mt-2">
                        <h6 v-if="imageHeader.length === 0">
                          No photos added yet
                        </h6>
                        <b-img
                          v-else
                          v-model="imageHeader"
                          center
                          fluid
                          :src="imageHeader[0].link"
                          height="100"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Event overview"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Event overview"
                        rules="required"
                      >
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="primary"
                          @click="isShowModalSelectImageOverview =! isShowModalSelectImageOverview"
                        >
                          Select image
                        </b-button>
                        <div class="border rounded p-2 pb-0 mt-2">
                          <h6 v-if="imageOverviewOnEdit.length === 0">
                            No photos added yet
                          </h6>
                          <b-img
                            v-else
                            v-model="imageOverviewOnEdit"
                            center
                            fluid
                            :src="imageOverviewOnEdit[0].link"
                            height="100"
                          />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Header images -->
                  <b-col md="6">
                    <b-form-group
                      label="Header images"
                      label-for="product-add-region"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header images"
                        rules="required"
                      >
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="primary"
                          @click="isShowModalSelectImage =! isShowModalSelectImage"
                        >
                          Select image
                        </b-button>
                        <div class="border rounded p-2 pb-0 mt-2">
                          <h6 v-if="imageHeaderOnEdit.length === 0">
                            No photos added yet
                          </h6>
                          <b-img
                            v-else
                            v-model="imageHeaderOnEdit"
                            center
                            fluid
                            :src="imageHeaderOnEdit[0].link"
                            height="100"
                          />
                        </div>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-button
              v-if="!idProduct"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="confirmProduct"
            >
              Add Asset
            </b-button>
            <b-button
              v-if="idProduct"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="confirmProduct"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :to="{ name: 'asset-manager' }"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </validation-observer>
    <select-image-modal
      :is-show-modal-select-image.sync="isShowModalSelectImage"
      :selected-box="imageHeader"
      :region="productEdit.region_id"
      @set-image-id="setImageId"
    />
    <select-images-modal
      :is-show-modal-select-images.sync="isShowModalSelectImages"
      :selected-box="imageHeader"
      :region="productEdit.region_id"
      @set-images-id="setImagesId"
    />
    <select-image-modal
      :is-show-modal-select-image.sync="isShowModalSelectImageOverview"
      :selected-box="imageHeader"
      :region="productEdit.region_id"
      @set-image-id="setImageIdOverview"
    />
    <select-image-modal
      :is-show-modal-select-image.sync="isShowModalImageSpeaker"
      :selected-box="imageHeader"
      :region="productEdit.region_id"
      @set-image-id="setImageSpeaker"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import flatPickr from 'vue-flatpickr-component'

import {
  BImg,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BLink,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import * as config from '@/utils/config'
// eslint-disable-next-line import/no-cycle
import moment from 'moment'
import { ref } from '@vue/composition-api'
import SelectImageModal from '@/views/modal/SelectImageModal.vue'
import SelectImagesModal from '@/views/modal/SelectImagesModal.vue'
import * as utils from '@/utils/utils'
import editProduct from './onEditProduct'
import 'swiper/swiper-bundle.css'

export default {
  components: {
    BImg,
    SelectImageModal,
    SelectImagesModal,
    Swiper,
    SwiperSlide,
    BCardBody,
    BLink,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      active: 'Active',
      inactive: 'Inactive',
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      readOnlyOption: {
        placeholder: 'Please choose region',
        theme: 'snow',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions', 'languageOptions']),
    ...mapGetters('product', ['productCategory']),
    ...mapGetters('product', ['productTags']),
    ...mapGetters('product', ['productType']),
    ...mapGetters('bussiness', ['productBussiess']),
    ...mapGetters('role', ['productRole']),
  },
  async created() {
    this.loading = true
    await store.dispatch('product/fetchTags')
    this.loading = false
  },
  setup() {
    const isShowModalSelectImage = ref(false)
    const isShowModalSelectImages = ref(false)
    const isShowModalSelectImageOverview = ref(false)
    const isShowModalImageSpeaker = ref(false)
    const currentIndexSpeaker = ref(null)
    const swiperOptions = {
      allowTouchMove: false,
      centeredSlides: true,
      slidesPerView: 2,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }
    const {
      arrayDescriptionOnEdit,
      countOnEdit,
      productOnEdit,
      imageHeader,
      imagesHowItWork,
      imageHeaderOnEdit,
      imagesHowItWorkOnEdit,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      updateItemForm,
      categorySelected,
      imageOverviewOnEdit,
      imageOverview,
    } = editProduct()
    return {
      arrayDescriptionOnEdit,
      countOnEdit,
      productOnEdit,
      swiperOptions,
      config,
      isShowModalSelectImage,
      isShowModalSelectImages,
      isShowModalSelectImageOverview,
      imageHeader,
      imagesHowItWork,
      imageHeaderOnEdit,
      imagesHowItWorkOnEdit,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      updateItemForm,
      categorySelected,
      imageOverviewOnEdit,
      imageOverview,
      currentIndexSpeaker,
      isShowModalImageSpeaker,
    }
  },

  methods: {
    async nextSlide(key) {
      await this.$refs[key].$swiper.slideNext()
    },
    async previousSlide(key) {
      await this.$refs[key].$swiper.slidePrev()
    },
    async removeElemenSpeaker(index) {
      await this.productOnEdit.speakers.splice(index, 1)
      await this.$refs.speakerRef.$swiper.slideTo(index - 1, 1)
    },
    async appendElemenSpeaker() {
      await this.productOnEdit.speakers.push({ name: null, image: null, role: null })
      await this.$refs.speakerRefOnedit.$swiper.slideTo(this.productOnEdit.speakers.length, 1)
    },
    setImageSpeaker(imageArray) {
      this.productOnEdit.speakers[this.currentIndexSpeaker].image = imageArray[0]
    },
    openSetImageSpeaker(index) {
      this.currentIndexSpeaker = index
      this.isShowModalImageSpeaker = true
    },
    setImageIdOverview(arrayImage) {
      this.imageOverviewOnEdit = arrayImage
    },
    onChangeTitle() {
      this.productOnEdit.slug = utils.toSlug(this.productOnEdit.title)
    },
    setImageId(arrayID) {
      this.imageHeaderOnEdit = arrayID
    },
    setImagesId(arrayID) {
      this.imagesHowItWorkOnEdit = arrayID
    },
    updateProductType(val) {
      this.productEdit.type = val
    },
    updateProductCategory(val) {
      this.productEdit.category_id = val
    },
    addNewItemInItemFormDescription() {
      this.arrayDescriptionOnEdit.push({
        title: '',
        description: '',
        region_id: '',
      })
      this.countOnEdit += 1
    },
    addNewItemInItemFormRegionPrice() {
      this.arrayDescriptionOnEdit.push({
        title: '',
        description: '',
        region_id: '',
      })
      this.countOnEdit += 1
    },
    removeItem(index) {
      this.arrayDescriptionOnEdit.splice(index, 1)
      this.countOnEdit -= 1
    },
    confirmProduct() {
      this.$refs.editProductValidation.validate().then(success => {
        const arrImage = this.imageHeaderOnEdit.concat(this.imagesHowItWorkOnEdit)
        const arrImageID = arrImage.map(x => x.id)
        if (success) {
          const productUpdateData = {
            title: this.productOnEdit.title,
            tag_ids: this.productOnEdit.tag_ids.map(x => x.value),
            product_role_ids: this.productOnEdit.product_roles.map(x => x.value),
            product_bussiness_challenge_ids: this.productOnEdit.product_bussiness_challenges.map(x => x.value),
            from_price: parseInt(this.productOnEdit.from_price, 10),
            slug: this.productOnEdit.slug,
            description: [{
              region_id: this.arrayDescriptionOnEdit[0].region_id.region,
              title: this.arrayDescriptionOnEdit[0].title,
              description: this.arrayDescriptionOnEdit[0].description,
              summary: this.arrayDescriptionOnEdit[0].summary,
            }],
            offering_type: 'event',
            metadata: {
              speakers: this.productOnEdit.speakers,
              startDatetime: moment(this.productOnEdit.startDateTime).utc().valueOf(),
              duration: this.productOnEdit.duration,
              imageOverview: this.imageOverviewOnEdit[0],
              regions_form: this.arrayDescription,
            },
            metadata2: {
              startDatetime: moment(this.productOnEdit.startDateTime).utc().valueOf(),
              duration: this.productOnEdit.duration,
              properties: this.arrayDescriptionOnEdit.map(x => ({
                region_id: x.region_id,
                title: x.title,
                description: x.description,
                summary: x.summary,
              })),
            },
            category_ids: this.productOnEdit.category_ids.map(x => x.value),
            media_ids: arrImageID,
          }
          store.dispatch('product/updateProductOnEdit', { id: this.idProduct, product: productUpdateData }).then(response => {
            if (response.request.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Update event successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.productEdit = null
              this.arrayDescription = []
              this.images = []
              this.loading = false
              router.push('/event-manager')
            }
          }).catch(e => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${e.message}`,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}
.swiper-button-next {
  color:#DB536A
}
.swiper-button-prev {
  color: #DB536A;
}
.select-image{
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  border: dash grey;
}
.back-sub-element {
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background-color: #DB536A;
}
.sub-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #DB536A;
  color: white;
  border-radius: 1rem;
  border-color: #DB536A;
  cursor: pointer;
  justify-content: center;
}
.add-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #DB536A;
  color: white;
  border-radius: 1rem;
  border-color: #DB536A;
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
  margin-bottom: 1rem;
}
</style>
